.pill {
  align-items: center;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  position: relative;

  &:hover {
    background: #f9f9f9;
  }
}

.emptyPill {
  border-style: solid;
  border-width: 2px;

  &:hover {
    background: none;
  }
}

.pillText {
  display: flex;
  flex-flow: column nowrap;
  padding-right: 14px;

  > h5 {
    font-size: 14px;
    margin: 0;
  }

  > small {
    margin-top: -4px;
  }
}

.pillLink {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  text-decoration: none;
  align-items: center;
  padding: 8px;
  width: 100%;

  > i:last-child {
    margin-left: auto;
  }
}

.emptyPillLink {
  p {
    margin-bottom: 0;
  }
}

.iconDrawer {
  align-items: center;
  display: flex;
  justify-content: center;
}
